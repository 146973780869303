import React from "react"
import Seo from "../shared/seo/seo"
import LayoutPortfolio from "../shared/layout-portfolio/layout-portfolio"
import ImgDetail from "../shared/img-detail/img-detail"
import { graphql, useStaticQuery } from "gatsby"

const Hotels = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          projects {
            hotels {
              title
            }
          }
        }
      }
    }
  `)
  return (
    <LayoutPortfolio>
      <Seo title={data.site.siteMetadata.projects.hotels.title}></Seo>
      <div className="container">
        <div className="row ">
          <div className="col mt-4">
            <ImgDetail
              folder="hotels"
              description="TAVOLO DI MASSELLO IN QUERCIA, STRUTTURA IN FERRO, MENSOLE LACCATE"
              path="rsz_le_masse_2.png"
            ></ImgDetail>
          </div>
        </div>
        <div className="row ">
          <div className="col mt-4">
            <ImgDetail
              folder="hotels"
              description="ISOLA CUCINA IN FERRO CRUDO, MOBILE LACCATO, TOP IN MARMO DI CARRARA"
              path="rsz_le_masse_1.png"
            ></ImgDetail>
          </div>
        </div>
        <div className="row ">
          <div className="col mt-4">
            <ImgDetail
              folder="hotels"
              description="PORTA E ARMADIO IN ASSI DA MURATORE TRATTATE"
              path="rsz_le_masse_4.png"
            ></ImgDetail>
          </div>
        </div>
        <div className="row ">
          <div className="col mt-4">
            <ImgDetail
              folder="hotels"
              description="CUCINA RIVESTITA IN FERRO CRUDO, TOP IN PIETRA E PARETI IN QUERCIA VECCHIA"
              path="rsz_campo_al_sole_3.png"
            ></ImgDetail>
          </div>
        </div>
      </div>
    </LayoutPortfolio>
  )
}

export default Hotels
